import React, { Component } from "react";
import { graphql } from "gatsby";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import MovLink from "../components/movlink";

// import { TimelineMax, TweenMax, Linear } from "gsap";
// import ScrollMagic from "scrollmagic";
// import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";

// import { FaRegClock } from "react-icons/fa";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import Img from "gatsby-image";
import Layout from "../layouts";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import HeroImage from "../components/heroimage";
// import { FaChevronDown } from "react-icons/fa";
// import { rhythm } from "../utils/typography";
// import styled from "@emotion/styled";
import {
  GridBoxContainer,
  GridSectionHeader,
  GridBox,
  GridHeader,
  PaddedMobile,
  PageSpacing,
  PageHeader,
  GreenButton,
  HeroContainer,
  HeroLinks,
  HeroTextOverlay,
  HeroTextOverlayInner,
} from "../utils/styles";
// import smoothscroll from 'smoothscroll-polyfill';

class Home extends Component {
  constructor(props) {
    super(props);

    let data = this.props.data;
    let imageLength = data.wordpressPage.acf.images.length;
    let randomImagePos = Math.floor(Math.random() * Math.floor(imageLength));

    this.state = {
      home: true,
      randomImage: randomImagePos,
    };

    this.myDivToFocus = React.createRef();

    // let randomImage = data.wordpressPage.acf.images[randomImagePos].image.localFile.childImageSharp.fluid
  }

  // load the smoothscroll here as it requires window:
  // https://github.com/webpack/react-starter/issues/37
  componentDidMount() {
    const smoothscroll = require("smoothscroll-polyfill");
    // kick off the polyfill!
    // this fixes ios smooth
    smoothscroll.polyfill();

    // scrollmagic stuff
    // var tween = TweenMax.to('#myElement', 1, {backgroundColor:"#eeeeee", opacity: 0.5})
    // new ScrollMagic.Scene({
    //   triggerElement: "#scrollStarts",
    //   tweenChanges: true,
    //   duration: 400, // scroll distance
    //   offset: 200 // start this scene after scrolling for 50px
    // })
    //   .setTween(TweenMax.to("#myElement"), 1, {x: 100})
    //   .addTo(this.controller); // assign the scene to the controller
  }

  handleOnClick = (event) => {
    //.current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  render() {
    const data = this.props.data;

    const regex = /(<([^>]+)>)/gi;
    const excerpt = data.wordpressPage.excerpt.replace(regex, "");

    return (
      <Layout location="home">
        <HeroContainer>
          <HeroImage
            image={
              data.wordpressPage.acf.images[this.state.randomImage].image
                .localFile.childImageSharp.fluid
            }
          />
          <HeroTextOverlay>
            <HeroTextOverlayInner>
              <div css={[HeroLinks, PageHeader]}>
                <div
                  css={[
                    GreenButton,
                    css`
                      margin-bottom: 15px;
                    `,
                  ]}
                >
                  <a href="https://www.arts.ac.uk/subjects/animation-interactive-film-and-sound/postgraduate/ma-user-experience-design-lcc#apply">
                    Apply
                  </a>
                </div>
                <div css={GreenButton}>
                  <a href="mailto:j.fass@arts.ac.uk">Contact</a>
                </div>
              </div>
            </HeroTextOverlayInner>
          </HeroTextOverlay>
        </HeroContainer>

        <Flex mx={[-1, -1, -2]} flexWrap="wrap" css={[PaddedMobile]}>
          <Box width={1} px={[1, 1, 2]}>
            <h1
              css={css`
                font-size: 1.6em;
              `}
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </Box>
          <Box width={1} p={[1, 1, 2]}>
            <h2 ref={this.myDivToFocus} css={[GridSectionHeader, PageSpacing]}>
              Recent Projects
            </h2>
          </Box>

          {data.allWordpressPost.edges.map(({ node }) => (
            <Box
              width={[1 / 2, 1 / 2, 1 / 4]}
              p={[1, 1, 2]}
              key={`box-${node.slug}`}
              css={GridBoxContainer}
            >
              <div css={GridBox} key={node.slug}>
                <MovLink
                  to={`/${node.slug}`}
                  direction="left"
                  css={{ textDecoration: `none` }}
                >
                  {node.featured_media && node.featured_media.localFile && (
                    <Img
                      // css={{ marginBottom: rhythm(1 / 2) }}
                      key={
                        node.featured_media.localFile.childImageSharp.fluid.src
                      }
                      fluid={
                        node.featured_media.localFile.childImageSharp.fluid
                      }
                    />
                  )}
                  <h3 css={GridHeader}>{node.title}</h3>
                </MovLink>

                {/* <div dangerouslySetInnerHTML={{ __html: node.excerpt }} /> */}
                {/* <PostIcons node={node} /> */}
              </div>
            </Box>
          ))}
        </Flex>
      </Layout>
    );
  }
}

export default Home;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 209 }) {
      title
      content
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        images {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: { wordpress_id: { ne: 2320 } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
